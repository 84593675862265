import { ControllerFlowAPI, HttpClient } from '@wix/yoshi-flow-editor';
import { request } from '../../../../services/request';
import { categories as categoriesMethod } from '../../../../api/categories/categories.api';
import { listCategories } from '@wix/ambassador-achievements-categories-v3-category/http';
import { shouldReportErrorMonitor } from '../../Challenge/helpers/shouldReportErrorMonitor';
import { handleError } from '../../../ErrorHandler/errorHandlerPropsMap';

async function requestCategoriesServerless() {
  return (await new HttpClient().request(categoriesMethod()))?.data;
}

export async function requestCategoriesHttp(flowAPI: ControllerFlowAPI) {
  let categories = [];

  try {
    categories =
      (await request(flowAPI, listCategories({})))?.data?.categories || [];
  } catch (error) {
    handleError({
      error,
      context: 'GetCategoriesList',
      preventErrorMonitorReport: !shouldReportErrorMonitor(error),
    });
  }

  return { categories };
}

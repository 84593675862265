import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { requestCategoriesHttp } from '../api/requestCategories';
import { handleError } from '../../../ErrorHandler/errorHandlerPropsMap';

export default async function handleUserLogin(flowAPI: ControllerFlowAPI) {
  flowAPI.controllerConfig.wixCodeApi.user.onLogin(async () => {
    let categories;

    try {
      categories = await requestCategoriesHttp(flowAPI);
    } catch (error) {
      handleError({
        error,
        context: 'onLogin.getCategories',
      });
    }

    flowAPI.controllerConfig.setProps({
      categoriesData: {
        ...categories,
      },
    });
  });
}

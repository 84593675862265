export enum ButtonState {
  Default = 'default',
  Hover = 'hover',
}

export enum CategoriesTabsState {
  Regular = 'regular',
  Hover = 'hover',
  Selected = 'selected',
}

export interface ISettingsEvents {
  buttonState: ButtonState;
  showMAEmptyList: boolean;
  setCategoryToPreview: string;
  categoriesTabChange: CategoriesTabsState;
}

export enum ChallengesCustomEvents {
  buttonState = 'buttonState',
  categoriesTabState = 'categoriesTabChange',
}
